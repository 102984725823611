<template>
  <router-view />
</template>

<script>
import standartsModule from '../store'

export default {
  name: 'StandartsCatalogView',
  props: {
    gid: [Number, String],
    cid: [Number, String],
    mid: [Number, String],
  },
  data() {
    return {
      error: '',
    }
  },
  computed: {
    ...standartsModule.mapGetters([
      'standartsCatalogGid',
      'standartsCatalogCid',
      'standartsCatalogMid',
    ]),
  },
  mounted() {
    this.init()
  },
  beforeCreate() {
    standartsModule.register()
  },
  destroyed() {
    standartsModule.unregister()
  },
  watch: {
    gid: 'setGid',
    cid: 'setCid',
    mid: 'setMid',
  },
  methods: {
    ...standartsModule.mapActions([
      'SET_STANDARTS_CATALOG_GID',
      'SET_STANDARTS_CATALOG_CID',
      'SET_STANDARTS_CATALOG_MID',
      'GET_STANDARTS_CATALOG_GROUP',
    ]),
    async init() {
      this.error = ''
      try {
        await Promise.all([
          this.gid && this.standartsCatalogGid != this.gid && this.setGid(),
          this.cid && this.standartsCatalogCid != this.cid && this.setCid(),
          this.mid && this.standartsCatalogMid != this.mid && this.setMid(),
        ])
      } catch (err) {
        // TODO как тут её корректно показать?
        this.error = err
      }
    },
    async setGid() {
      await this.SET_STANDARTS_CATALOG_GID(this.gid)
    },
    async setCid() {
      await this.SET_STANDARTS_CATALOG_CID({ gid: this.gid, cid: this.cid })
    },
    async setMid() {
      await this.SET_STANDARTS_CATALOG_MID({
        gid: this.gid,
        cid: this.cid,
        mid: this.mid,
      })
    },
  },
}
</script>
