<template>
  <v-list-item class="pl-8" v-if="title">
    <v-list-item-content class="py-1">
      <v-row no-gutters dense align="center">
        <v-col
          cols="12"
          sm="12"
          md="2"
          lg="2"
          class="align-end text-subtitle-2"
        >
          {{ title }}
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="10"
          lg="10"
          class="text-pre-wrap text-body-2"
        >
          <span v-html="label" />
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'StandartsListSubItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
}
</script>
