import api from '@/lib/axiosApi'

export class EApiStandartsException extends Error {
  constructor(message) {
    super(`[standarts] ${message}`)
    this.name = 'EApiStandartsException'
  }
  static check(std) {
    if (!std) {
      throw new this('Стандарт не может быть пустым')
    }
  }
  static checkId(param, msg = '') {
    if ((typeof param === 'object' && !param?.id) || !param) {
      throw new this(msg + ' (отсутствует id)')
    }
  }
}

export const standartsCatalog = {
  getStdGroupList: async () => {
    const list = await api.get(`std/pr/`)
    const data = {
      list,
      count: list?.length,
      caption: 'Группы по профилям стандартов',
    }
    return data
  },

  getStdClinrecList: async params => {
    const gid = Number(params?.gid || 0)
    EApiStandartsException.checkId(gid, 'Группа не определена')

    const list = await api.get(`std/pr/${gid}/cr`)
    const data = {
      gid,
      list,
      count: list?.length,
      caption: 'Клинические рекомендации',
    }
    return data
  },

  getStdStandartList: async params => {
    const gid = Number(params?.gid || 0)
    EApiStandartsException.checkId(gid, 'Группа не определена')

    const cid = Number(params?.cid || 0)
    EApiStandartsException.checkId(
      cid,
      'Клиническая рекомендация не определена'
    )

    const list = await api.get(`std/pr/${gid}/cr/${cid}/std`)

    // TODO На подумать - надо ли?

    // // ищем клинрек
    // let { default: clinrec } = await import(
    //   /* webpackChunkName: "fakeapi" */ './std_clinrec_list.json'
    // )
    // clinrec = clinrec.find(item => item.id === cid)?.caption || ''

    const data = {
      cid,
      list,
      count: list.length,
      caption: `Стандарты медицинской помощи`, // (${clinrec})`,
    }

    return data
  },

  getStdStandartModule: async params => {
    const id = Number(params?.id || 0)
    EApiStandartsException.checkId(
      id,
      `Стандарт медицинской помощи (id:${id}) не определён`
    )

    const { data = {} } = await api.get(`std/pr/-/cr/-/std/${id}`)

    return data
  },
}

export default standartsCatalog
