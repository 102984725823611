<template>
  <v-container fluid v-if="!error">
    <template v-if="loading">
      <v-row dense>
        <v-col offset-md="1" md="10">
          <v-progress-linear indeterminate color="secondary" rounded striped />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row dense class="mt-4" no-gutters v-if="canReqCalcStandarts">
        <v-col offset-md="1" md="10" class="d-flex">
          <v-spacer />
          <!--             :label="`${
              modeCalc ? 'Выключить' : 'Включить'
            } расчёт потребности по утверждённому стандарту:`" -->
          <v-switch
            v-model="modeCalc"
            label="Расчёт потребности"
            hide-details
            dense
            inset
          />
        </v-col>
      </v-row>
      <v-row dense v-if="modeCalc" align="center">
        <v-col
          offset-md="1"
          md="10"
          class="d-flex"
          style="align-items: center !important"
        >
          <v-spacer />
          <span class="text-subtitle-1 source_card mr-8 mt-2">
            Количество активно лечащихся пациентов для данного стандарта:</span
          >
          <v-text-field
            v-model="numberPacients"
            :rules="numberRules"
            single-line
            required
            reverse
            type="number"
            style="max-width: 100px"
          />
          <v-btn
            :disabled="btnCalcEnable()"
            class="ml-8"
            outlined
            color="primary"
            @click="caclculateAction"
          >
            Рассчитать
          </v-btn>
        </v-col>
      </v-row>

      <v-row dense class="mt-4" no-gutters>
        <v-col offset-md="1" md="6">
          <p class="mb-0" :class="titleClass">Паспорт стандарта:</p>
        </v-col>
      </v-row>
      <v-row dense class="mt-4">
        <v-col offset-md="1" md="10">
          <module-passport :data="passport" />
        </v-col>
      </v-row>

      <template v-for="table in tables">
        <v-row dense v-if="data?.exist?.[table?.exist]" :key="table.exist">
          <v-col offset-md="1" md="10">
            <ModuleTables
              ref="moduleTables"
              :data="data"
              :calcRate="calcRate"
              :options="table.options"
              :title="table?.title"
              :footnotes="notes"
              @noteValues="setNote"
            />
          </v-col>
        </v-row>
      </template>

      <v-row dense v-if="activeNotes?.length">
        <v-col offset-md="1" md="10">
          <module-subs :items="activeNotes" />
        </v-col>
      </v-row>
    </template>
  </v-container>
  <v-container fluid v-else>
    <v-row>
      <v-col>
        <v-alert type="error" dense outlined>
          Произошла ошибка при загрузки Модуля:
          <br />
          {{ error_message }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import standartsModule from '../../store'
import parts from './settings/parts'
import ModulePassport from './parts/ModulePassport.vue'
import ModuleTables from './parts/ModuleTables.vue'
import ModuleSubs from './parts/ModuleSubs.vue'

export default {
  name: 'StandartModule',
  components: { ModulePassport, ModuleTables, ModuleSubs },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    error: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      parts,
      numberPacients: 1000,
      calcRate: 0,
      numberRules: [
        v => !!v || 'Поле не может быть пустым',
        v => Number.isInteger(Number(v)) || 'Значение должно быть целым числом',
        v => v > 0 || 'Значение должно быть больше 0',
      ],
      notes: [1],
      modeCalc: false,
    }
  },
  computed: {
    ...standartsModule.mapGetters([
      // 'canReadStandarts',
      'canReqCalcStandarts',
    ]),
    tables() {
      return this.parts?.tables || []
    },
    passport() {
      return this.data?.passport || {}
    },
    activeNotes() {
      const an = []
      let title = ''

      if (this.parts?.subs?.length) {
        this.notes.forEach((note, index) => {
          title = this.parts.subs[note - 1]?.title || ''
          title = `<sup>${index + 1}</sup> ${title}`
          an.push(title)
        })
      }

      return an
    },
    titleClass() {
      return this.mobile ? 'text-subtitle-1' : 'text-h6'
    },
  },
  methods: {
    caclculateAction() {
      const count = +this.numberPacients
      this.notes = [1]
      if (count) this.calcRate = count
    },
    btnCalcEnable() {
      const np = +this.numberPacients
      return !(np && np > 0 && Number.isInteger(Number(np)))
    },
    setNote(val) {
      if (!this.notes.filter(x => x == val)?.length) this.notes.push(val)
    },
  },
}
</script>
