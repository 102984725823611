<template>
  <div id="standarts-catalog-clinrec-pages">
    <PageTitle :title="title" :loading="loading" icon />
    <StandartsCatalogClinRec :id="gid" />
  </div>
</template>

<script>
import standartsModule from '../store'
import { SCN } from '../config/standartsListFields'
import StandartsCatalogClinRec from '../entity/StandartsCatalogClinRec.vue'
import PageTitle from '@/components/common/PageTitle.vue'
export default {
  name: 'StandartsCatalogClinRecPage',
  components: { StandartsCatalogClinRec, PageTitle },
  props: {
    gid: [Number, String],
  },
  computed: {
    ...standartsModule.mapGetters(['standartsCatalogNames']),
    title() {
      return this.standartsCatalogNames?.[SCN.GROUP] || ''
    },
    loading() {
      // загрузка пока нет названия группы
      return !this.standartsCatalogNames?.[SCN.GROUP]
    },
  },
}
</script>
