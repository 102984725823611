<template>
  <div id="standarts-catalog-standart-pages">
    <PageTitle :title="title" :loading="loading" icon />
    <StandartsCatalogModule :id="mid" />
  </div>
</template>

<script>
import standartsModule from '../store'
import { SCN } from '../config/standartsListFields'
import StandartsCatalogModule from '../entity/StandartsCatalogModule.vue'
import PageTitle from '@/components/common/PageTitle.vue'
export default {
  name: 'StandartsCatalogModulePage',
  components: { StandartsCatalogModule, PageTitle },
  props: {
    gid: [Number, String],
    cid: [Number, String],
    mid: [Number, String],
  },
  computed: {
    ...standartsModule.mapGetters(['standartsCatalogNames']),
    title() {
      return this.standartsCatalogNames?.[SCN.STANDART]
    },
    loading() {
      // загрузка пока нет названия группы
      return !this.standartsCatalogNames?.[SCN.STANDART]
    },
  },
}
</script>
