<template>
  <div>
    <div v-for="(option, index) in options" :key="`${option.field}-${index}`">
      <div v-if="exist(option?.exist)">
        <p class="mb-0" :class="titleClass">
          {{ getTitle(option) }}
        </p>
        <div v-for="id in option.id" :key="id">
          <ModuleTable
            :data="getData(id, option)"
            :key="id"
            :footnotes="footnotes"
            @noteValue="setNote"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleTable from './ModuleTable.vue'
import { copyObject } from '@/lib/objects'
import { smartRound, ntl } from '@/lib/strUtils'
export default {
  components: { ModuleTable },
  name: 'ModuleTables',
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
    calcRate: {
      type: Number,
      default: 0,
      required: false,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    title: {
      type: String,
      default: '',
      required: false,
    },
    footnotes: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      calcRateVal: 0,
    }
  },

  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    },
    titleClass() {
      return this.mobile ? 'text-subtitle-1' : 'text-h6'
    },
  },
  watch: {
    calcRate(val) {
      if (Number(val)) this.calcRateVal = val
    },
  },
  methods: {
    getData(id, option = {}) {
      const { id: ids = [], field: field_data = '', calc = [] } = option
      let calc_fields, calc_value, calc_value_f

      if (!ids.length) return {}
      const { data = [], ...fields } = this.data?.[field_data] || {}
      const fieldsCopy = copyObject(fields)
      const rows =
        ids.length === 1 ? data : data.filter(el => el?.page_id === id)

      if (rows?.length && this.calcRateVal && calc?.length) {
        rows.forEach(row => {
          calc.forEach(el => {
            calc_fields = el?.fields || []
            calc_value =
              (calc_fields.length &&
                calc_fields.reduce(
                  (acc, rec) =>
                    typeof +row[rec] === 'number' && acc * +row[rec],
                  1
                )) ||
              '-'
            if (el?.field) {
              calc_value_f =
                typeof calc_value === 'number'
                  ? ntl(smartRound(this.calcRateVal * calc_value))
                  : calc_value

              calc_value_f = `${calc_value_f} ${
                el?.measure && row[el.measure] ? row[el.measure] : ''
              }`
              if (
                el?.check &&
                row[el.check] !== null &&
                el?.measure &&
                row[el.measure]
              ) {
                calc_value_f += ` доз (${row[el.check]})`
              }
              row[el.field] = calc_value_f
            }
          })
        })
        // Добавление в таблицу полей вывода расчёта
        calc.forEach(el => {
          if (el?.field) {
            fieldsCopy.field.push(el.field)
            fieldsCopy.title.push(el.title)
          }
        })
      }

      return { id, data: rows, ...fieldsCopy, options: option }
    },
    getTitle(option) {
      // Предназначенное title из настроек
      if (this.title) return this.title

      const { id = [], field = '' } = option
      if (!id?.length) return ''
      const list = this.data[field] || {}
      const { page = [] } = list
      const row_fild = page.find(el => el.id === option.id[0])
      return row_fild?.title || ''
    },
    exist(val) {
      if (!val) return true

      return this.data?.exist?.[val]
    },
    setNote(val) {
      this.$emit('noteValues', val)
    },
  },
}
</script>
