<template>
  <v-flex>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="mb-2 font-weight-bold"
    >
      <template v-if="item.show">
        <span v-html="item.title" />
        <br v-if="item.br" />
        <span class="text-pre-wrap font-weight-regular" v-html="item.value" />
      </template>
    </div>
  </v-flex>
</template>

<script>
import parts from '../settings/parts'
export default {
  name: 'ModulePassport',
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  computed: {
    items() {
      const { passport } = parts
      return passport.map(el => {
        return {
          title: el.title || '',
          value: this.data?.[el.field] || '',
          br: el.newline,
          show: el.strict ? el.value && el.strict : true,
        }
      })
    },
  },
}
</script>
