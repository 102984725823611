<template>
  <v-container fluid>
    <template v-if="!error">
      <v-row dense v-if="loading">
        <v-col offset-md="1" md="10">
          <v-progress-linear indeterminate color="secondary" rounded striped />
        </v-col>
      </v-row>
      <v-row dense v-else align="center">
        <v-col offset-md="1" md="7" class="py-0">
          <span> {{ title }} </span>
        </v-col>
        <v-col md="3" v-if="filter?.field">
          <div class="d-flex justify-end">
            <div :class="{ 'text--disabled': !filterSwith }">
              {{ filter?.title }}
            </div>
            <v-switch
              v-model="standartsFilterSwith[filter.field]"
              @change="doChange()"
              hide-details
              dense
              inset
              class="ml-5"
            />
          </div>
        </v-col>
      </v-row>
      <v-row dense v-if="!loading">
        <v-col offset-md="1" md="10" v-if="listItemsFiltered?.length">
          <v-list v-if="fields?.length" :threeLine="subfields?.length === 2">
            <v-list-group
              v-for="(item, index) in listItemsFiltered"
              :key="`${index} - ${item.caption}`"
              no-action
              v-model="item.active"
            >
              <template v-slot:activator>
                <v-list-item-content class="pt-0">
                  <v-list-item-title>
                    <div class="pt-4">
                      <div
                        class="secondary--text font-weight-medium"
                        :title="item.caption"
                      >
                        <!--  TODO paramName == 'mid'  КОСТЫЛЬ!-->
                        <span v-if="item?.count || paramName == 'mid'">
                          <span class="mr-1 d-inline-block text-truncate">
                            {{ index + 1 }}.
                          </span>
                          <router-link
                            :to="{
                              name: routeName,
                              params: { [paramName]: item.id },
                            }"
                            class="secondary--text"
                          >
                            <span
                              @click="clickHandler"
                              class="d-inline-block text-truncate text-decoration-underline"
                              style="max-width: 97%"
                            >
                              {{ item.caption }}
                            </span>
                          </router-link>
                          <sup class="text-caption">
                            {{ item?.count }}
                          </sup>
                        </span>
                        <div v-else class="text--disabled">
                          <v-badge
                            color="blue lighten-3"
                            overlap
                            offset-x="-10"
                            offset-y="10"
                            content="в работе"
                            class="d-inline-block"
                            style="max-width: calc(100% - 80px)"
                          >
                            <span class="mr-2 d-inline-block text-truncate">
                              {{ index + 1 }}.
                            </span>
                            <div
                              class="d-inline-block text-truncate"
                              style="max-width: 97%"
                            >
                              {{ item.caption }}
                            </div>
                          </v-badge>
                        </div>
                      </div>
                    </div>
                  </v-list-item-title>
                  <template v-if="!item?.active">
                    <v-list-item-subtitle
                      :key="field.value"
                      v-for="field in subfields"
                      class="mb-0"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          sm="12"
                          md="2"
                          lg="2"
                          class="font-weight-bold text-caption"
                          :class="index < 9 ? 'pl-5' : 'pl-7'"
                        >
                          {{ field.subfield?.title }}
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="10"
                          lg="10"
                          class="text-pre-wrap text-caption"
                          :class="index < 9 ? 'pl-5' : 'pl-7'"
                        >
                          {{ getListItemSubtitle(item, field) }}
                        </v-col>
                      </v-row>
                    </v-list-item-subtitle>
                  </template>
                </v-list-item-content>
              </template>

              <template v-for="field in fields">
                <StandartsListSubItem
                  v-if="item?.[field.value]"
                  :key="field.value"
                  :title="field.title"
                  :label="item[field.value]"
                />
              </template>
            </v-list-group>
          </v-list>
          <v-list v-else>
            <v-list-item-group>
              <v-list-item v-for="(item, index) in listItems" :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="pt-0">
                      <span
                        class="secondary--text font-weight-medium d-inline-block text-truncate"
                        :title="item.caption"
                      >
                        <span v-if="item?.count || paramName == 'gid'">
                          <span class="mr-1"> {{ index + 1 }}. </span>
                          <router-link
                            :to="{
                              name: routeName,
                              params: { [paramName]: item.id },
                            }"
                            class="secondary--text"
                          >
                            <span
                              @click="clickHandler"
                              className="text-decoration-underline"
                              :class="{
                                'text--disabled':
                                  paramName == 'gid' && !item?.count,
                              }"
                              style="max-width: 97%"
                            >
                              {{ item.caption }}
                            </span>
                          </router-link>
                          <sup class="text-caption" v-if="item?.count">
                            {{ item?.count }}
                          </sup>
                        </span>
                        <span class="text--disabled" v-else>
                          <span class="mr-2"> {{ index + 1 }}. </span>
                          <span
                            class="text-decoration-underline"
                            style="max-width: 97%"
                          >
                            {{ item.caption }}
                          </span>
                        </span>
                      </span>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col offset-md="1" md="10" v-else>
          <div class="text-h6" v-if="paramName == 'cid'">
            Утверждённые СМП отсутствуют.
          </div>
        </v-col>
      </v-row>
    </template>

    <v-row v-else>
      <v-col>
        <v-alert type="error" dense outlined>
          Произошла ошибка при работе с каталогом Стандартов Медицинской Помощи:
          <br />
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import standartsModule from '../../store'
import { copyObject } from '@/lib/objects'
import StandartsListSubItem from './StandartsListSubItem.vue'

export default {
  name: 'StandartsLisItems',
  components: { StandartsListSubItem },
  props: {
    routeName: {
      type: String,
      default: '/',
      required: true,
    },
    paramName: {
      type: String,
      default: 'id',
      required: false,
    },
    caption: {
      type: String,
      default: '',
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    error: {
      type: [Error, String],
      default: '',
      required: false,
    },
    filter: {
      type: Object,
      default: () => {},
      required: false,
    },
  },

  data() {
    return {
      listItems: [],
      filterSwith: false,
    }
  },

  computed: {
    ...standartsModule.mapGetters(['standartsFilterSwith']),
    title() {
      return this.caption ? this.caption + ':' : ''
    },
    subfields() {
      return this.fields?.length ? this.fields.filter(el => el?.subfield) : []
    },
    listItemsFiltered() {
      return this.filterSwith
        ? this.listItems.filter(el => el.count)
        : this.listItems
    },
  },

  watch: {
    items: 'init',
  },

  mounted() {
    this.init()
  },

  methods: {
    ...standartsModule.mapMutations(['SET_STANDARTS_FILTER_SWITH']),
    init() {
      this.listItems = copyObject(this.items)
      const { field = '' } = this.filter || {}
      this.filterSwith = this.standartsFilterSwith[field]
    },
    clickHandler() {
      console.log(this.routeName)
    },
    getListItemSubtitle(item, field) {
      return typeof field.subfield?.func === 'function'
        ? field.subfield.func(item[field.value])
        : item[field.value]
    },
    doChange() {
      const { field = '' } = this.filter || {}
      const value = this.standartsFilterSwith[field]
      this.SET_STANDARTS_FILTER_SWITH({
        field: this.filter.field,
        value: value,
      })
      this.filterSwith = !this.filterSwith
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-item {
    min-height: 28px !important;
  }
  .v-list-group {
    & sup {
      top: -0.7em !important;
    }
  }
  .v-input--selection-controls {
    margin-top: -3px;
    padding-top: 4px;
  }
}
</style>
