<template>
  <div id="standarts-catalog-pages">
    <PageTitle title="Cтандарты медицинской помощи" icon />
    <StandartsCatalogGroup v-if="isLoggedIn" />
    <LoadingImg v-else />
  </div>
</template>

<script>
import StandartsCatalogGroup from '../entity/StandartsCatalogGroup.vue'
import { mapGetters /*, mapActions */ } from 'vuex'
import PageTitle from '@/components/common/PageTitle.vue'
import LoadingImg from '@/components/common/LoadingImg.vue'
export default {
  name: 'StandartsCatalogGroupPage',
  components: { StandartsCatalogGroup, PageTitle, LoadingImg },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
}
</script>
