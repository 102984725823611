const parts = {
  passport: [
    {
      title: 'Возрастная категория пациента: ',
      field: 'age_cat',
    },
    {
      title: 'Пол пациента: ',
      field: 'sex',
    },
    {
      title: 'Вид медицинской помощи: ',
      field: 'type_mp_std',
    },
    {
      title: 'Условия оказания медицинской помощи: ',
      field: 'cond_mp_std',
    },
    {
      title: 'Форма оказания медицинской помощи: ',
      field: 'form_mp_std',
    },
    {
      title: 'Фаза течения заболевания (состояния): ',
      field: 'phase',
      strict: true,
    },
    {
      title: 'Стадия и (или) степень тяжести заболевания (состояния): ',
      field: 'stage',
      strict: true,
    },
    {
      title: 'Осложнения: ',
      field: 'complications',
      strict: true,
    },
    {
      title:
        'Средняя продолжительность лечения законченного случая (количество дней): ',
      field: 'duration_mid',
    },
    {
      title: 'Нозологические единицы (код по МКБ X<sup>1</sup>): ',
      field: 'diag_full',
      newline: true,
    },
  ],
  tables: [
    {
      exist: 'services',
      options: [
        {
          exist: 'services_1',
          field: 'services',
          id: [1, 2, 3, 4],
          headers: [
            {
              width: '150px',
              cellClass: 'text-start',
              align: 'center',
            },
            {
              width: '520px',
              align: 'start',
            },
            {
              width: '150px',
              align: 'center',
            },
            {
              width: '16%',
              align: 'center',
            },
            {
              width: '16%',
              align: 'center',
            },
          ],
          footnotes: [
            {
              page_id: 1,
              notes: [
                {
                  field: 'MidRate_f',
                  label: 'Усредненный показатель частоты предоставления2',
                  value: 2,
                },
                {
                  field: 'midmult_c',
                  label: 'Потребность в количестве услуг',
                  value: 7,
                },
              ],
            },
          ],
          calc: [
            {
              title: 'Потребность в количестве услуг',
              fields: ['MidRate', 'MidMult'],
              field: 'midmult_c',
            },
          ],
        },
        {
          field: 'services',
          exist: 'services_2',
          id: [5, 6, 7, 8, 9, 10, 11],
          headers: [
            {
              width: '150px',
              cellClass: 'text-start',
              align: 'center',
            },
            {
              width: '520px',
              align: 'start',
            },
            {
              width: '150px',
              align: 'center',
            },
            {
              width: '16%',
              align: 'center',
            },
            {
              width: '16%',
              align: 'center',
            },
          ],
          calc: [
            {
              title: 'Потребность в количестве услуг',
              fields: ['MidRate', 'MidMult'],
              field: 'midmult_c',
            },
          ],
        },
      ],
    },
    {
      exist: 'medicines',
      options: [
        {
          field: 'medicines',
          id: [12],
          headers: [
            {
              width: '100px',
              cellClass: 'text-start',
              align: 'center',
            },
            {
              width: '150px',
              cellClass: 'text-start',
              align: 'center',
            },
            {
              width: '150px',
              cellClass: 'text-start',
              align: 'center',
            },
            {
              width: '120px',
              align: 'center',
            },
            {
              width: '100px',
              align: 'center',
            },
            {
              width: '100px',
              align: 'center',
            },
            {
              width: '10%',
              align: 'center',
            },
            {
              width: '16%',
              align: 'center',
            },
          ],
          footnotes: [
            {
              page_id: 12,
              notes: [
                {
                  field: 'MNN',
                  label: 'Наименование лекарственного препарата3',
                  value: 3,
                },
                {
                  field: 'mdd',
                  label: 'ССД4',
                  value: 4,
                },
                {
                  field: 'mcd',
                  label: 'СКД5',
                  value: 5,
                },
                {
                  field: 'mdd_c',
                  label: 'Расчетная потребность_',
                  value: 6,
                },
              ],
            },
          ],
          calc: [
            {
              title: 'Расчетная потребность',
              fields: ['dose_course', 'scaling', 'MidRate'],
              field: 'mdd_c',
              check: 'form_dose',
              measure: 'Unit',
            },
          ],
        },
      ],
    },
    {
      exist: 'blood',
      options: [
        {
          field: 'blood',
          id: [13],
          headers: [
            {
              width: '650px',
              cellClass: 'text-start',
              align: 'center',
            },
            {
              width: '150px',
              align: 'center',
            },
            {
              width: '150px',
              align: 'center',
            },
            {
              width: '120px',
              align: 'center',
            },
            {
              width: '10%',
              align: 'center',
            },
          ],
          footnotes: [
            {
              page_id: 13,
              notes: [
                {
                  field: 'dose_day_f',
                  label: 'ССД4',
                  value: 4,
                },
                {
                  field: 'dose_course_f',
                  label: 'СКД5',
                  value: 5,
                },
                {
                  field: 'blood_c',
                  label: 'Расчетная потребность',
                  value: 8,
                },
              ],
            },
          ],
          calc: [
            {
              title: 'Расчетная потребность',
              fields: ['dose_course', 'MidRate'],
              field: 'blood_c',
            },
          ],
        },
      ],
    },
    {
      exist: 'medstuff',
      options: [
        {
          field: 'medstuff',
          id: [14],
          headers: [
            {
              width: '150px',
              cellClass: 'text-start',
              align: 'center',
            },
            {
              width: '670px',
              cellClass: 'text-start',
              align: 'center',
            },
            {
              width: '200px',
              align: 'center',
            },
            {
              width: '15%',
              align: 'center',
            },
            {
              width: '16%',
              align: 'center',
            },
          ],
          calc: [
            {
              title: 'Расчетная потребность',
              fields: ['MidCount', 'MidRate'],
              field: 'stuff_c',
            },
          ],
        },
      ],
    },
    {
      exist: 'medfood',
      title:
        '6. Виды лечебного питания, включая специализированные продукты лечебного питания, имеющие государственную регистрацию',
      options: [
        {
          field: 'medfood_1',
          exist: 'medfood_1',
          id: [15],
          headers: [
            {
              width: '900px',
              cellClass: 'text-start',
              align: 'center',
            },
            {
              width: '200px',
              align: 'center',
            },
            {
              width: '15%',
              align: 'center',
            },
            {
              width: '16%',
              align: 'center',
            },
          ],
          calc: [
            {
              title: 'Расчетная потребность',
              fields: ['mid_rate', 'count'],
              field: 'food1_c',
            },
          ],
        },
        {
          field: 'medfood_2',
          exist: 'medfood_2',
          id: [16],
          headers: [
            {
              width: '700px',
              cellClass: 'text-start',
              align: 'center',
            },
            {
              width: '150px',
              align: 'center',
            },
            {
              width: '150px',
              align: 'center',
            },
            {
              width: '100px',
              align: 'center',
            },
            {
              width: '15%',
              align: 'center',
            },
            {
              width: '16%',
              align: 'center',
            },
          ],
          footnotes: [
            {
              page_id: 16,
              notes: [
                {
                  field: 'dose_f',
                  label: 'ССД4',
                  value: 4,
                },
                {
                  field: 'dose_course_f',
                  label: 'СКД5',
                  value: 5,
                },
              ],
            },
          ],
          calc: [
            {
              title: 'Расчетная потребность',
              fields: ['dose_course', 'MidRate'],
              field: 'food2_c',
            },
          ],
        },
      ],
    },
  ],
  subs: [
    {
      value: 1,
      title:
        'Международная статистическая классификация болезней и проблем, связанных со здоровьем, X пересмотра.',
    },
    {
      value: 2,
      title:
        'Вероятность предоставления медицинских услуг или назначения лекарственных препаратов для медицинского применения (медицинских изделий), включенных в стандарт медицинской помощи, которая может принимать значения от 0 до 1, где 1 означает, что данное мероприятие проводится 100% пациентов, соответствующих данной модели, а цифры менее 1 - указанному в стандарте медицинской помощи проценту пациентов, имеющих соответствующие медицинские показания.',
    },
    {
      value: 3,
      title:
        'Международное непатентованное, или группировочное, или химическое, а в случаях их отсутствия – торговое наименование лекарственного препарата.',
    },
    {
      value: 4,
      title: 'Средняя суточная доза.',
    },
    {
      value: 5,
      title: 'Средняя курсовая доза.',
    },
    {
      value: 6,
      title: 'Расчётная потребность ЛП на лиц с диагнозом, единицы измерения.',
    },
    {
      value: 7,
      title: 'Расчётная потребность в количестве услуг на лиц с диагнозом',
    },
    {
      value: 8,
      title: 'Расчётная потребность на лиц с диагнозом, единицы измерения.',
    },
  ],
}

export default parts
