<template>
  <StandartsListItems
    routeName="StandartsClinRec"
    :items="stdsCatalogGroup"
    :caption="caption"
    :loading="loading"
    :error="error"
    paramName="gid"
  />
</template>

<script>
import standartsModule from '../store'
import StandartsListItems from './components/StandartsListItems.vue'

export default {
  name: 'StandartsCatalogGroup',
  components: { StandartsListItems },
  data() {
    return {
      caption: '',
      stdsCatalogGroup: [],
      loading: false,
      error: '',
    }
  },

  mounted() {
    this.init()
  },
  computed: {
    ...standartsModule.mapGetters(['standartsCatalogGroup']),
  },

  methods: {
    ...standartsModule.mapActions(['GET_STANDARTS_CATALOG_GROUP']),
    async init() {
      this.loading = true
      this.error = ''
      try {
        await this.GET_STANDARTS_CATALOG_GROUP()
        const { list, caption } = this.standartsCatalogGroup
        this.stdsCatalogGroup = list
        this.caption = caption
        this.caption = caption
      } catch (err) {
        this.error = err
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
