import { createNamespacedHelpers } from 'vuex'
import { registerModule, unregisterModule } from '@/store'
import { standartsModule } from './const'

import standarts from './standartsCatalog'

export default {
  name: standartsModule,
  register: () =>
    registerModule(standartsModule, { ...standarts, namespaced: true }),
  unregister: () => unregisterModule(standartsModule),
  // map store
  ...createNamespacedHelpers(standartsModule),
}
