<template>
  <div class="img"></div>
</template>

<script>
export default {
  name: 'LoadingImg',
}
</script>

<style scoped>
.img {
  background-image: url('~@/assets/img.jpg');
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
