import api from '../api'
import Vue from 'vue'
import { SCN } from '../config/standartsListFields'
import { STATUS } from '@/store'

export class EApiStandartsDataException extends Error {
  constructor(message) {
    super(`[standartCatalog] ${message}`)
    this.name = 'EApiStandartsDataException'
  }
  static raiseIfLoading(loadingState) {
    if (loadingState !== STATUS.IDLE)
      throw new this(`Каталог стандартов находится на стадии - ${loadingState}`)
  }
}

const state = {
  loadingStandartsCatalogGroup: STATUS.IDLE,
  loadingStandartsCatalogClinRec: STATUS.IDLE,
  loadingStandartsCatalogStandart: STATUS.IDLE,
  loadingStandartsCatalogModule: STATUS.IDLE,

  gid: null,
  cid: null,
  mid: null,
  standartsCatalogNames: {},

  standartsCatalogGroup: {},
  standartsCatalogClinRec: {},
  standartsCatalogStandart: {},
  standartsCatalogModule: {},

  standartsFilterSwith: {},
}

const getters = {
  loadingStandartsCatalogGroup: state => state.loadingStandartsCatalogGroup,
  loadingStandartsCatalogClinRec: state => state.loadingStandartsCatalogClinRec,
  loadingStandartsCatalogStandart: state =>
    state.loadingStandartsCatalogStandart,
  loadingStandartsCatalogModule: state => state.loadingStandartsCatalogModule,

  standartsCatalogNames: state => state.standartsCatalogNames,

  standartsCatalogGid: state => state.gid,
  standartsCatalogCid: state => state.cid,
  standartsCatalogMid: state => state.mid,

  standartsCatalogGroup: state => state.standartsCatalogGroup,
  standartsCatalogClinRec: state => state.standartsCatalogClinRec,
  standartsCatalogStandart: state => state.standartsCatalogStandart,
  standartsCatalogModule: state => state.standartsCatalogModule,

  standartsFilterSwith: state => state.standartsFilterSwith,

  // Пользователь может просматривать фильтры
  canReadStandarts: (state, getters, rootState, rootGetters) =>
    rootGetters.isAdmin || rootGetters.userAttributes?.std?.canRead || false,
  // Пользователь может производить расчёт потребностей
  canReqCalcStandarts: (state, getters, rootState, rootGetters) =>
    rootGetters.isAdmin || rootGetters.userAttributes?.std?.canReqCalc || false,
}

const mutations = {
  SET_DEFAULT_STANDARTS_CATALOG(state) {
    state.gid = null
    state.cid = null
    state.mid = null
    state.standartsCatalogNames = {}
    state.standartsCatalogGroup = {}
    state.standartsCatalogClinRec = {}
    state.standartsCatalogModule = {}
    state.loadingStandartsCatalogGroup = STATUS.IDLE
    state.loadingStandartsCatalogClinRec = STATUS.IDLE
    state.loadingStandartsCatalogStandart = STATUS.IDLE
    state.loadingStandartsCatalogModule = STATUS.IDLE
    state.standartsFilterSwith = {}
  },

  SET_STANDARTS_CATALOG_NAME(state, data) {
    const { field, name = '' } = data
    Vue.set(state.standartsCatalogNames, field, name)
    // state.standartsCatalogNames[field] = name
  },

  SET_STANDARTS_CATALOG_GID(state, id) {
    state.gid = id
  },

  SET_STANDARTS_CATALOG_CID(state, id) {
    state.cid = id
  },

  SET_STANDARTS_CATALOG_MID(state, id) {
    state.mid = id
  },

  SET_LOADING_STANDARTS_CATALOG_GROUP(state, st = STATUS.IDLE) {
    state.loadingStandartsCatalogGroup = st
  },

  SET_LOADING_STANDARTS_CATALOG_CLINREC(state, st = STATUS.IDLE) {
    state.loadingStandartsCatalogClinRec = st
  },

  SET_LOADING_STANDARTS_CATALOG_STANDART(state, st = STATUS.IDLE) {
    state.loadingStandartsCatalogStandart = st
  },

  SET_LOADING_STANDARTS_CATALOG_MODULE(state, st = STATUS.IDLE) {
    state.loadingStandartsCatalogModule = st
  },

  SET_STANDARTS_CATALOG_GROUP(state, data = {}) {
    state.standartsCatalogGroup = data
  },

  SET_STANDARTS_CATALOG_CLINREC(state, data = {}) {
    state.standartsCatalogClinRec = data
  },

  SET_STANDARTS_CATALOG_STANDART(state, data = {}) {
    state.standartsCatalogStandart = data
  },

  SET_STANDARTS_CATALOG_MODULE(state, data = {}) {
    state.standartsCatalogModule = data
  },

  SET_STANDARTS_FILTER_SWITH(state, filter) {
    if (filter?.field) {
      state.standartsFilterSwith[filter.field] = filter?.value
    }
  },
}

const actions = {
  // загрузить все стандарты (группы)
  async GET_STANDARTS_CATALOG_GROUP({ commit, getters }) {
    // уходим если уже каталог загружен
    if (getters.standartsCatalogGroup?.list?.length) return

    EApiStandartsDataException.raiseIfLoading(
      getters.loadingStandartsCatalogGroup
    )
    try {
      commit('SET_LOADING_STANDARTS_CATALOG_GROUP', STATUS.LOADING)
      commit('SET_STANDARTS_CATALOG_GROUP', {})

      const data = await api.getStdGroupList()

      commit('SET_STANDARTS_CATALOG_GROUP', data)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_STANDARTS_CATALOG_GROUP',
          text: `Ошибка получения списка групп по профилям стандартов`,
          err,
        },
        { root: true }
      )
      throw err
    } finally {
      commit('SET_LOADING_STANDARTS_CATALOG_GROUP', STATUS.IDLE)
    }
  },

  // загрузить все стандарты (группы по Клин Рек)
  async GET_STANDARTS_CATALOG_CLINREC({ commit, dispatch, getters }, id) {
    // уходим если уже каталог загружен
    if (!id || id === getters.standartsCatalogClinRec?.gid) return

    EApiStandartsDataException.raiseIfLoading(
      getters.loadingStandartsCatalogClinRec
    )
    try {
      commit('SET_LOADING_STANDARTS_CATALOG_CLINREC', STATUS.LOADING)
      commit('SET_STANDARTS_CATALOG_CLINREC', {})

      const data = await api.getStdClinrecList({ gid: id })

      commit('SET_STANDARTS_CATALOG_CLINREC', data)
      await dispatch('SET_STANDARTS_CATALOG_GID', id)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_STANDARTS_CATALOG_CLINREC',
          text: `Ошибка получения списка групп по клин рек стандартов`,
          err,
        },
        { root: true }
      )
      throw err
    } finally {
      commit('SET_LOADING_STANDARTS_CATALOG_CLINREC', STATUS.IDLE)
    }
  },

  // загрузить все стандарты (стандарты мед помощи)
  async GET_STANDARTS_CATALOG_STANDART({ commit, dispatch, getters }, id) {
    // уходим если уже каталог загружен
    if (!id || id === getters.standartsCatalogStandart?.cid) return

    EApiStandartsDataException.raiseIfLoading(
      getters.loadingStandartsCatalogStandart
    )
    try {
      commit('SET_LOADING_STANDARTS_CATALOG_STANDART', STATUS.LOADING)
      commit('SET_STANDARTS_CATALOG_STANDART', {})

      const data = await api.getStdStandartList({
        gid: 1, // не имеет значения для бэка сейчас ! (как бы и не нужна))
        cid: id,
      })

      commit('SET_STANDARTS_CATALOG_STANDART', data)
      await dispatch('SET_STANDARTS_CATALOG_CID', id)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_STANDARTS_CATALOG_STANDART',
          text: `Ошибка получения списка групп по клин рек стандартов`,
          err,
        },
        { root: true }
      )
      throw err
    } finally {
      commit('SET_LOADING_STANDARTS_CATALOG_STANDART', STATUS.IDLE)
    }
  },

  // загрузить все модули (описание стандарта мед помощи)
  async GET_STANDARTS_CATALOG_MODULE({ commit, dispatch, getters }, id) {
    // уходим если уже каталог загружен
    if (!id || id === getters.standartsCatalogModule?.mid) return

    EApiStandartsDataException.raiseIfLoading(
      getters.loadingStandartsCatalogModule
    )
    try {
      commit('SET_LOADING_STANDARTS_CATALOG_MODULE', STATUS.LOADING)
      commit('SET_STANDARTS_CATALOG_MODULE', {})

      const data = await api.getStdStandartModule({ id })

      commit('SET_STANDARTS_CATALOG_MODULE', data)
      await dispatch('SET_STANDARTS_CATALOG_MID', id)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_STANDARTS_CATALOG_MODULE',
          text: `Ошибка получения модуля описания стандарта`,
          err,
        },
        { root: true }
      )
      throw err
    } finally {
      commit('SET_LOADING_STANDARTS_CATALOG_MODULE', STATUS.IDLE)
    }
  },

  async SET_STANDARTS_CATALOG_GID({ commit, dispatch, getters }, id) {
    if (!id || id === getters.standartsCatalogGid) return

    try {
      commit('SET_STANDARTS_CATALOG_NAME', {
        field: SCN.GROUP,
        name: '',
      })

      if (
        !getters.standartsCatalogGroup?.list?.length &&
        getters.loadingStandartsCatalogGroup === STATUS.IDLE
      ) {
        await dispatch('GET_STANDARTS_CATALOG_GROUP')
      }
      const { list = [] } = getters.standartsCatalogGroup
      const group = list.find(el => el.id == id)

      commit('SET_STANDARTS_CATALOG_GID', id)
      commit('SET_STANDARTS_CATALOG_NAME', {
        field: SCN.GROUP,
        name: group?.caption || '',
      })
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'SET_STANDARTS_CATALOG_GID',
          text: `Ошибка установки ID и Имени группы каталога`,
          err,
        },
        { root: true }
      )
      throw err
    }
  },

  async SET_STANDARTS_CATALOG_CID({ commit, dispatch, getters }, { gid, cid }) {
    if (!gid || !cid || cid === getters.standartsCatalogCid) return

    try {
      commit('SET_STANDARTS_CATALOG_NAME', {
        field: SCN.CLIN_REC,
        name: '',
      })

      if (
        !getters.standartsCatalogClinRec?.list?.length &&
        getters.loadingStandartsCatalogClinRec === STATUS.IDLE
      ) {
        await dispatch('GET_STANDARTS_CATALOG_CLINREC', gid)
      }
      const { list = [] } = getters.standartsCatalogClinRec
      const group = list.find(el => el.id == cid)
      commit('SET_STANDARTS_CATALOG_CID', cid)
      commit('SET_STANDARTS_CATALOG_NAME', {
        field: SCN.CLIN_REC,
        name: group?.caption || '',
      })
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'SET_STANDARTS_CATALOG_CID',
          text: `Ошибка установки ID и Имени группы клинических рекомендаций`,
          err,
        },
        { root: true }
      )
      throw err
    }
  },

  async SET_STANDARTS_CATALOG_MID(
    { commit, dispatch, getters },
    { gid, cid, mid }
  ) {
    if (!gid || !cid || !mid || mid === getters.standartsCatalogMid) return

    try {
      commit('SET_STANDARTS_CATALOG_NAME', {
        field: SCN.STANDART,
        name: '',
      })

      if (
        !getters.standartsCatalogStandart?.list?.length &&
        getters.loadingStandartsCatalogStandart === STATUS.IDLE
      ) {
        await dispatch('GET_STANDARTS_CATALOG_STANDART', cid)
      }
      const { list = [] } = getters.standartsCatalogStandart
      const group = list.find(el => el.id == mid)
      commit('SET_STANDARTS_CATALOG_MID', mid)
      commit('SET_STANDARTS_CATALOG_NAME', {
        field: SCN.STANDART,
        name: group?.caption || '',
      })
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'SET_STANDARTS_CATALOG_CID',
          text: `Ошибка установки ID и Имени стандарта`,
          err,
        },
        { root: true }
      )
      throw err
    }
  },

  SET_DEFAULT_STANDARTS_CATALOG({ commit }) {
    commit('SET_DEFAULT_STANDARTS_CATALOG')
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
