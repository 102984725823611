<template>
  <div
    id="report-table"
    class="v-data-table"
    :class="{ 'v-data-table--dense theme--light v-data-table--mobile': mobile }"
  >
    <div class="v-data-table__wrapper">
      <table class="r-table" v-if="!mobile">
        <thead>
          <tr>
            <th
              v-if="mainHeader?.text"
              :colspan="headers.length"
              class="main-header"
              :class="mainHeader.class"
            >
              {{ mainHeader.text }}
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="index"
              class="text-subtitle-1"
              :class="getClass(header)"
              :style="{
                width: header.width,
                verticalAlign: header.verticalAlign,
              }"
            >
              <slot :name="'header.' + header.value">
                <!-- {{ header?.text }} -->
              </slot>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index_row) in items" :key="index_row">
            <td
              v-for="(header, index) in headers"
              :key="index"
              :class="getClass(header, 'cellClass')"
            >
              <slot :name="'item.' + header.value" :item="(item, index_row)">
                {{ item[header.value] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="r-table-mobile" v-else>
        <thead class="v-data-table-header v-data-table-header-mobile">
          <tr>
            <th>
              <div
                class="v-data-table-header-mobile__wrapper"
                :class="mainHeader?.class"
              >
                {{ mainHeader.text }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index_row) in items"
            :key="index_row"
            class="v-data-table__mobile-table-row"
          >
            <td
              v-for="(header, index) in headers"
              :key="index"
              class="v-data-table__mobile-row"
              :style="{
                verticalAlign: header.verticalAlignMobile,
              }"
            >
              <div class="v-data-table__mobile-row__header">
                <slot :name="'header.' + header.value">
                  {{ header?.text }}
                </slot>
              </div>
              <div class="v-data-table__mobile-row__cell">
                <slot :name="'item.' + header.value" :item="(item, index_row)">
                  {{ item[header.value] }}
                </slot>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportTable',
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    mainHeader: {
      type: Object,
      required: false,
    },
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    },
  },
  methods: {
    getClass(header, cl = 'class') {
      const align =
        cl === 'class'
          ? header?.align || ''
          : header?.[cl]
          ? ''
          : header?.align || ''

      const res = `${
        header?.[cl] || '' + (align.trim() != '' ? `text-${align}` : '')
      }`

      return res
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep table {
  &.r-table {
    // border: 1px solid #6cf;
    border: thin solid rgba(0, 0, 0, 0.12);

    border-collapse: collapse;
    th {
      padding: 12px 16px !important;
      border: thin solid rgba(0, 0, 0, 0.12);
      align-items: flex-start !important;
    }
    td {
      padding: 8px 16px;
      border: thin solid rgba(0, 0, 0, 0.12);
    }
  }
  &.r-table-mobile {
    tr {
      margin-top: 8px;
      margin-bottom: 8px;
    }
    tr > th {
      padding: 0 6px !important;
    }
    td {
      &.v-data-table__mobile-row {
        padding: 4px 8px;
        min-height: 38px;
        align-items: flex-start !important;
      }
    }
  }
}
</style>
