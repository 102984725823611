<template>
  <v-flex class="mb-5">
    <report-table
      :headers="headers"
      :items="items"
      :mainHeader="mainHeader"
      v-if="items.length"
    >
      <template v-for="(h, index) in headers" #[`header.${h.value}`]>
        <span :key="index" v-html="h.text" />
      </template>
    </report-table>
  </v-flex>
</template>

<script>
import ReportTable from '@/components/ui/table/ReportTable'
export default {
  components: { ReportTable },
  name: 'ModuleTable',
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
    footnotes: {
      type: Array,
      default: () => [],
      required: false,
    },
  },

  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    },
    items() {
      return this.data?.data || []
    },
    headers() {
      const result = []
      const { field = [], title = [], options = {} } = this.data
      for (let index = 0; index < field.length; index++) {
        const header = {
          value: field[index],
          text: `${this.getTitle(title?.[index], field[index], options)}${
            this.mobile ? ':' : ''
          }`,
          align:
            index < options?.headers.length
              ? options?.headers[index]?.align
              : '',
          width:
            index < options?.headers.length
              ? options?.headers[index]?.width
              : '',
          cellClass:
            index < options?.headers.length
              ? options?.headers[index]?.cellClass
              : '',
          verticalAlignMobile:
            index < options?.headers.length
              ? options?.headers[index]?.verticalAlignMobile
              : '',
          verticalAlign:
            index < options?.headers.length
              ? options?.headers[index]?.verticalAlign
              : '',
          class: '',
        }
        result.push(header)
      }
      return result
    },
    mainHeader() {
      const { page = [] } = this.data
      const header = this.data?.id
        ? page.find(el => el.id === this.data?.id)
        : [{}]
      const text = header?.subtitle || ''
      return {
        text: text,
        class: 'text-start text-subtitle-1',
      }
    },
  },

  methods: {
    getTitle(title = '', field = '', options) {
      if (options?.footnotes?.length) {
        let note = options.footnotes.find(el => el.page_id === this.data?.id)
        const notes = note?.notes || []
        note = notes.find(el => el.field === field)
        if (note?.value) {
          // есть сноска
          let index = this.footnotes.findIndex(el => el == note.value)

          if (index === -1) {
            index = this.footnotes.length
          }
          title = title + `<sup>${index + 1}</sup>`
          this.$emit('noteValue', note.value)
        }
        // const index = this.footnotes.find(el => el == note.value)
        // title = note?.value ? title + `<sup>${note.value}</sup>` : title
        // note?.value && this.$emit('noteValue', note.value)
      }

      return title
    },
  },
}
</script>
