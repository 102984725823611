<template>
  <StandartsListItems
    routeName="StandartsModule"
    :items="list"
    :fields="standartFields"
    :caption="caption"
    :loading="loading"
    :error="error"
    paramName="mid"
  />
</template>

<script>
// import api from '@/api'
import standartsModule from '../store'
import { standartFields } from '../config/standartsListFields'
import StandartsListItems from './components/StandartsListItems.vue'

export default {
  name: 'StandartsCatalogStandart',
  components: { StandartsListItems },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      caption: '',
      list: [],
      standartFields,
      loading: false,
      error: '',
    }
  },

  computed: {
    ...standartsModule.mapGetters(['standartsCatalogStandart']),
  },

  mounted() {
    this.init()
  },

  methods: {
    ...standartsModule.mapActions(['GET_STANDARTS_CATALOG_STANDART']),
    async init() {
      if (this.id) {
        this.loading = true
        this.error = ''
        try {
          await this.GET_STANDARTS_CATALOG_STANDART(this.id)
          const { list, caption } = this.standartsCatalogStandart
          this.list = list
          this.caption = caption
        } catch (err) {
          this.error = err
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>
