<template>
  <StandartModule :data="data" :loading="loading" :error="error" />
</template>

<script>
import standartsModule from '../store'
import StandartModule from './components/StandartModule.vue'

export default {
  name: 'StandartsCatalogModule',
  components: { StandartModule },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      data: {},
      loading: false,
      error: '',
    }
  },

  computed: {
    ...standartsModule.mapGetters(['standartsCatalogModule']),
  },

  mounted() {
    this.init()
  },

  methods: {
    ...standartsModule.mapActions(['GET_STANDARTS_CATALOG_MODULE']),
    async init() {
      if (this.id) {
        this.loading = true
        this.error = ''
        try {
          await this.GET_STANDARTS_CATALOG_MODULE(this.id)
          this.data = this.standartsCatalogModule || {}
        } catch (err) {
          this.error = err
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>
