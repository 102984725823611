<template>
  <v-flex>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="mb-2 ml-4 text-caption"
    >
      <template v-if="item">
        <div v-html="item" />
      </template>
    </div>
  </v-flex>
</template>

<script>
export default {
  name: 'ModuleSubs',
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
}
</script>
