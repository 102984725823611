<template>
  <StandartsListItems
    routeName="StandartsStandart"
    :items="list"
    :fields="clinRecFields"
    :caption="caption"
    :loading="loading"
    :error="error"
    :filter="filter"
    paramName="cid"
  />
</template>

<script>
import standartsModule from '../store'
import { clinRecFields } from '../config/standartsListFields'
import StandartsListItems from './components/StandartsListItems.vue'

export default {
  name: 'StandartsCatalogClinRec',
  components: { StandartsListItems },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      caption: '',
      list: [],
      clinRecFields,
      loading: false,
      error: '',
      filter: {
        title: 'Только утверждённые',
        field: 'count',
        defaultStateOn: true,
      },
    }
  },

  computed: {
    ...standartsModule.mapGetters([
      'standartsCatalogClinRec',
      'standartsFilterSwith',
    ]),
  },

  mounted() {
    this.init()
    const { field = '' } = this.filter || {}
    // eslint-disable-next-line no-prototype-builtins
    if (!this.standartsFilterSwith.hasOwnProperty(field)) {
      this.SET_STANDARTS_FILTER_SWITH({
        field: this.filter.field,
        value: this.filter.defaultStateOn,
      })
    }
  },

  methods: {
    ...standartsModule.mapActions(['GET_STANDARTS_CATALOG_CLINREC']),
    ...standartsModule.mapMutations(['SET_STANDARTS_FILTER_SWITH']),
    async init() {
      if (this.id) {
        this.loading = true
        this.error = ''
        try {
          await this.GET_STANDARTS_CATALOG_CLINREC(this.id)
          const { list, caption } = this.standartsCatalogClinRec
          this.list = list
          this.caption = caption
        } catch (err) {
          this.error = err
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>
