<template>
  <div id="standarts-catalog-standart-pages">
    <PageTitle :title="title" :loading="loading" icon />
    <StandartsCatalogStandart :id="cid" />
  </div>
</template>

<script>
import standartsModule from '../store'
import { SCN } from '../config/standartsListFields'
import StandartsCatalogStandart from '../entity/StandartsCatalogStandart.vue'
import PageTitle from '@/components/common/PageTitle.vue'
export default {
  name: 'StandartsCatalogStandartPage',
  components: { StandartsCatalogStandart, PageTitle },
  props: {
    gid: [Number, String],
    cid: [Number, String],
  },
  computed: {
    ...standartsModule.mapGetters(['standartsCatalogNames']),
    title() {
      return this.standartsCatalogNames?.[SCN.CLIN_REC]
    },
    loading() {
      // загрузка пока нет названия группы
      return !this.standartsCatalogNames?.[SCN.CLIN_REC]
    },
  },
}
</script>
